import { Config } from "config/config.type"
/** @firescoutMockVar Config.config */
const config:Config = {
  "env": "prod",
  "location": "lusini_de-ch",
  "shopName": "lusini",
  "locale": "de-ch",
  "rootCategoryId": "EMG",
  "orderMinimumB2C": 20,
  "availableCreditCards": [
    "MASTERCARD",
    "VISA"
  ],
  "links": {
    "contact": "/helpandservice/kontakt/"
  },
  "i18n": {
    "currency_ISO": "CHF",
    "locale": "de-CH",
    "country": "CH"
  },
  "slugifyLocale": "de",
  "curHrefLang": "de-ch",
  "hrefLang": [
    {
      "configFolder": "lusini",
      "locale": "de-de",
      "hrefLang": "de"
    },
    {
      "configFolder": "lusini",
      "locale": "de-at",
      "hrefLang": "de-at"
    },
    {
      "configFolder": "lusini",
      "locale": "de-ch",
      "hrefLang": "de-ch"
    }
  ],
  "baseUrl": "https://www.lusini.com",
  "modules": {
    "onetrust": {
      "dataDomainScript": "bda0c016-94be-4fd9-943a-b9c09791a327"
    },
    "buzdev": {
      "errorUrl": "https://buz.lusini.com/webhook/com.lusini.commerce-platform/error/1-0-1"
    },
    "cloudinary": {
      "endpoint": "https://res.cloudinary.com/lusini/",
      "transformations": {
        "freisteller_l": "w_1500,h_1500,q_80,c_pad,f_auto",
        "freisteller_l_2": "w_1500,h_1500,q_80,c_pad,f_auto",
        "milieu_l": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "milieu_l_2": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "freisteller_m": "w_500,h_500,q_70,c_pad,f_auto",
        "freisteller_m_2": "w_500,h_500,q_70,c_pad,f_auto",
        "milieu_m": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "milieu_m_2": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "freisteller_s": "w_300,h_300,q_70,c_pad,f_auto",
        "freisteller_s_2": "w_600,h_600,q_70,c_pad,f_auto",
        "milieu_s": "w_300,h_300,g_auto,q_70,c_fill,f_auto",
        "milieu_s_2": "w_600,h_600,g_auto,q_70,c_fill,f_auto",
        "freisteller_xs": "w_50,h_50,q_50,c_pad,fl_lossy,f_auto",
        "freisteller_xs_2": "w_100,h_100,q_50,c_pad,fl_lossy,f_auto",
        "milieu_xs": "w_50,h_50,g_auto,q_50,c_fill,fl_lossy,f_auto",
        "milieu_xs_2": "w_100,h_100,g_auto,q_50,c_fill,fl_lossy,f_auto"
      },
      "fallback_product_picture": "https://res.cloudinary.com/lusini/image/upload/v1643182695/application/products/lusini-online-shop-platzhalter-produktbild-in-kuerze-de.svg"
    },
    "shopware": {
      "salutations": [
        "mr",
        "ms",
        "not_specified"
      ],
      "initialSaluationId": "6d0b33d96b5b400cbabc648c0a4c3f60",
      "accessKey": {
        "b2b": "SWSC30KE09Q2GH2O75ME7K07VO",
        "b2c": "SWSCI3BE30S03QWFQ36PCPL6HS"
      },
      "storefrontUrl": {
        "b2b": "https://www.lusini.com/de-ch/b2b",
        "b2c": "https://www.lusini.com/de-ch/b2c"
      },
      "apiUrl": "https://shop-api.lusini.com/store-api",
      "languageId": "a731b492174f42c5992277babd1c751c"
    },
    "algolia": {
      "apiKey": "bf4bf9193d5f34cd369647376521dbc8",
      "applicationId": "WAVYBEW6OQ"
    },
    "contentful": {
      "space": "aza65graowyr",
      "environment": "master",
      "accessToken": "OExq-bY8Ujs7M06rQEL1drC2kfafk-qmEzA8DZO0CCE",
      "previewToken": "QP7JxRhc0mYWGrX3I8vj9WdBO_eF7n9r0xnmcX11HAw"
    },
    "dyHybrid": {
      "apiKey": "36ac5fd76da488da1815369c11a826ac2cca1d12df164068917706c430418a29",
      "campaignID": "9879542"
    },
    "trustShop": {
      "placeholderImg": "https://res.cloudinary.com/lusini/image/upload/c_scale,w_100/v1670412947/application/icons/trusted-shops-guarantee.svg",
      "id": "X84AFDDDDE99B0E42D70AAD60CFFED39E",
      "url": "https://widgets.trustedshops.com/js/",
      "urlPostfix": ".js"
    },
    "zenloop": {
      "id": "WTJFM05qSXhPREV0T0RJeE1DMDBNekEyTFdFME0yRXRZelptWWpneVptRXhPV1F5"
    },
    "hotjar": {
      "id": 3549008,
      "version": 6
    },
    "taboola": {
      "id": "1603278"
    }
  },
  "index": {
    "products": "prod_lusini_de_CH_products",
    "productsPriceAsc": "prod_lusini_de_CH_products_priceAsc",
    "categories": "prod_lusini_de_CH_categories",
    "series": "prod_lusini_de_CH_series",
    "productsSuggestion": "prod_lusini_de_CH_products_query_suggestions",
    "productsLatest": "prod_lusini_de_CH_products_noveltiesAsc",
    "productReviews": "prod_lusini_product_reviews"
  },
  "deliveryDate": {
    "shippingTooLong": 200
  },
  "colors": {
    "Weiss": "white",
    "Schwarz": "black",
    "Grau": "grey",
    "Silber": "silver",
    "Braun": "brown",
    "Blau": "blue",
    "Mehrfarbig": "multicolor",
    "Rot": "red",
    "Beige": "beige",
    "Grün": "green",
    "Transparent": "transparent",
    "Gelb": "yellow",
    "Gold": "gold",
    "Orange": "orange",
    "Rosa/Lila": "lilac"
  },
  "features": {
    "newsletterInitallyChecked": true,
    "onetrust": true,
    "creditCheck": false,
    "devMode": false,
    "trustShop": true,
    "zenloop": true,
    "b2c": true,
    "userLikeWidgetUrl": "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/8fc2912f95134be6897db79329669b42184e6ef23ef24dbb84098bbf98581714.js",
    "customizableUrl": "https://www.lusini.com/de-ch/helpandservice/serviceleistungen/personalisierung/",
    "taboola": true,
    "b2cHomePage": true,
    "notificationBar": true
  },
  "langShop": {
    "current": {
      "language": "Sprache",
      "locale": "de-CH",
      "label": "Deutsch"
    },
    "alternate": [
      {
        "label": "Deutsch",
        "url": "https://www.lusini.com/de-ch/",
        "active": true,
        "locale": "de-CH"
      },
      {
        "label": "Français",
        "url": "https://www.lusini.com/fr-ch/",
        "active": false,
        "locale": "fr-CH"
      }
    ]
  },
  "abTests": {}
}
export default config